import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import CardView from './CardView';
import "swiper/css/navigation";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./slider.css";

// import required modules
import { FreeMode, Pagination, Navigation } from "swiper";

const SliderView = (props) => {

  const {onSlideClick, sliderList = []} = props;

  return (
    <>
      <Swiper
        slidesPerView={2.136}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
        // when window width is >= 640px
        320: {
          width: 320,
          slidesPerView: 1,
          spaceBetween: 10
        },
        640: {
          width: 640,
          slidesPerView: 1,
          spaceBetween: 10
        },
        // when window width is >= 768px
        768: {
          width: 768,
          slidesPerView: 2,
          spaceBetween: 10
        },
        1020: {
          width: 1020,
          slidesPerView: 3.136,
          spaceBetween: 30
        }
      }}
        navigation={true}
        modules={[FreeMode, Navigation]}
        className="mySwiper"
      >
        {sliderList.map((item, index) => (
          <SwiperSlide onClick={() => onSlideClick(item, index)}>
            <CardView item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default SliderView;