import {useEffect, useRef} from 'react';
import { Route, Link} from 'react-router-dom';
/*import logo from '../../assets/cropped-logo-modified.png';*/
import './navbar.css';

const Navbar = () => {
  const btnRef = useRef();

  useEffect(() => {
    const navbar = document.querySelector('.navbar1');
    window.addEventListener("scroll", function() {
     // navbar.classList.toggle("sticky", window.scrollY > 20);
    });
  }, []);

  const onMenuItemClick = () => {
    const Menu = document.querySelector('.navbar1 .menu');
    const icon = document.querySelector('.menu-btn i');
    Menu.classList.toggle("active");
    icon.classList.toggle("active");
  }

  const onMenuBtnClick = () => {
    const Menu = document.querySelector('.navbar1 .menu');
    const icon = document.querySelector('.menu-btn i');
    Menu.classList.toggle("active");
    icon.classList.toggle("active");
  }

  return (
    <nav class="navbar1">
        <div class="max-width">
            <div class="logo"><a href="#">smm:consulting</a></div>
            <ul class="menu">
                <li><a href="#about" style={{"--i" : 1 }} class="menu-btn" onClick = {onMenuItemClick}>Work</a></li>
                <li><a href="#services" style={{"--i" : 2}} class="menu-btn" onClick = {onMenuItemClick}>Services</a></li>
                <li><a href="#team" style={{"--i" : 3}} class="menu-btn" onClick = {onMenuItemClick}>About</a></li>
                <li><a href="#skills" style={{"--i" : 4}} class="menu-btn" onClick = {onMenuItemClick}>Skills</a></li>
                <li><a href="#contact" style={{"--i" : 5}} class="menu-btn" onClick = {onMenuItemClick}>Contact</a></li>
            </ul>
            <div class="menu-btn" onClick={onMenuBtnClick}>
              <i class="fas fa-bars"></i>
            </div>
        </div>
    </nav>
  )
}

export default Navbar;