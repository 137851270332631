import profile_5 from '../assets/profile-5.jpeg';
import './cardview.css';

const CardView = (props) => {
	const {item = {}} = props;

	return (
		<div class="border-dark pb-3 h-100">
			<img src={item.image} class="pb-3" style={{height: '300px'}} />
			<div className="d-flex justify-content-between">
				<div>
					{/*<h3 class="font_600 px-3">{item.title}</h3>*/}
					<div class="client_name px-3 font_600 pt-2">
						{item.client_desc}
					</div>
					<div class="px-3 py-2">
						{item.sector.map((tag, index) => (
							<span className="px-1 slide_title_tag">#{tag.name}</span>
						))}
					</div>
				</div>
				<div>
					<i class="fa-solid fa-chevrons-right"></i>
				</div>
			</div>
		</div>
	)
}

export default CardView;