import React, {useState} from 'react';
import './footer.css';
import {Link} from 'react-router-dom';
import ContactusDialog from '../../components/ContactusDialog';

const Footer = () => {
  const [isShowContactDialog, setShowContactDialog] = useState(false);
 
return(
<footer class="footer py-5" id="contact">
  <div class="container">
	 	<div class="row">
      <div class="col-lg-6 d-flex align-items-center">
  	 		<div>
  	 			<h1 class="h1_text">Let's talk.</h1>
  	 		</div>
      </div>

      <div class="col-lg-6">
        <div class="d-flex justify-content-between">
          <h2>smm:consulting</h2>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div><a className="mail" href="mailto:saurav@smmc.net.in">saurav@smmc.net.in</a></div>
        </div>
        <hr />
        <div>
          <a className="mail" href="https://www.linkedin.com/in/saurav-majumder" target="_blank">LinkedIn</a>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-lg-6">
        <div className="py-3 d-flex align-items-center">
          <h4>SMMC - Copyright @ 2023</h4>
          <h4 className="ps-5"><Link target="_blank" to="/policy" className="policy">Policy</Link></h4>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-flex justify-content-around align-items-center py-3">
          <h4><a href="#about">Work</a></h4>
          <h4><a href="#services">Services</a></h4>
          <h4><a href="#team">About us</a></h4>
          <h4 onClick={() =>setShowContactDialog(true)}><a href="javascript:void(0)">Contact</a></h4>
        </div>
      </div>
    </div>
  </div>
  {isShowContactDialog && <ContactusDialog isOpen = {isShowContactDialog} onHandleCloseDialog={() => setShowContactDialog(false)} />}
    
</footer>
)}

export default Footer;