import React, {useState, useEffect} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import SpecialList from '../assets/SpecialList.js';
import graph from '../assets/graph.png';
import './dialog.css';

export default function FullDialog(props) {
  const {isOpen, children, item = {}, onHandleCloseDialog} = props;
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onHandleCloseDialog(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);


  return (
    <React.Fragment>
      <Dialog 
        onClose={handleClose} 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-label=""
        >
        <div className="dialog_content pb-5">
          <div className="dialog_header px-lg-5 px-4 py-4">
            <div>
              <h3>Our Services</h3>
            </div>
          </div>

          <div className="container-fluid bg-black py-3">
            <h4 className="text-white px-lg-5 px-4">How we can help you</h4>
            <div className="text-white pt-2 px-lg-5 px-4">Wherever you are in your business cycle, our solutions will help you overcome challenges and propel your growth.</div>
            <img className="py-5" src={graph} style={{width : '100%'}} />
          </div>

          <div className="container-fluid py-3 px-4 px-lg-5">
            <div className="my-3">
              <h4 className="">Our Specialities</h4>
            </div>
            <hr />
          </div>

          {SpecialList.map((item, index) => 
            <div className="container-fluid special_list px-4 px-lg-5">
                <div className="d-lg-flex d-md-flex align-items-center justify-content-between">
                  <div className="dialog_field_name pe-4 d-xs-flex d-lg-block align-items-center justify-lg-content-center">
                    <h4>{item.index}.</h4>
                    <div className="py-2">
                      <h4 className="px-1 px-lg-0 px-md-0">{item.title}</h4>
                    </div>
                  </div>
                  
                  <div class="serv">
                    <ul>
                      {item.listItem.map((option, i) => {
                        return <li className="option">{option.label}</li>
                      })
                      }
                    </ul>
                  </div>

                </div>
                {index !== SpecialList.length - 1 ? <hr /> : null}
            </div>
          )}

          <button className="dialog_close ax-button" onClick={handleClose}>
            <i class="fa-regular fa-circle-xmark" style={{fontSize: '30px', opacity: .7}}></i>
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
