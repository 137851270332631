import React, {useState} from 'react';
import SliderView from '../../components/SliderView';
import CaseStudy from '../../assets/CaseStudy.js';
import CaseStudyFullDialog from '../../components/CaseStudyFullDialog';
import './work.css';

const About = () => {

	const [selectedItem, setSelectedItem] = useState(null);
	const [isShowCaseStudyDialog, setShowCaseStudyDialog] = useState(false);
   
	const onSlideClick = (item) => {
        setSelectedItem(item);
        setShowCaseStudyDialog(true);
    }

    return(
    	<>
		<section className="about" id="about">
	        <div className="max-width">
	            <h4 className="font_700">Featured case studies</h4>
	            <h2>Projects delivered. Problems solved</h2>
	            <div className="d-lg-flex d-md-flex justify-content-between feature_container">
	                
	                <div className="py-4">
	                    <h4>Business consulting</h4>
	                    <div className="position-relative feature_value_container">
	                        <div className="position-absolute value_text w-50">150</div>
	                        <div className="position-absolute d-flex flex-column ps-4 info w-50">
                        		<div className="plus_text">+</div>
                        		<div className="plus_text">man months</div>
                    		</div>
	                    </div>
	                </div>

	                <div className="py-4">
	                    <h4>Design consulting</h4>
	                    <div className="position-relative feature_value_container">
	                    	<div className="position-absolute value_text w-50">50</div>
	                        <div className="position-absolute d-flex flex-column  info w-50">
	                            <div className="plus_text">+</div>
	                            <div className="plus_text">man months</div>
	                        </div>
	                    </div>
	                </div>

	                <div className="py-4">
	                    <h4>Development Services</h4>
	                    <div className="position-relative feature_value_container">
	                    	<div className="position-absolute value_text w-50">100</div>
	                        <div className="position-absolute d-flex flex-column ps-1 info w-50">
	                            <div className="plus_text">+</div>
	                            <div className="plus_text">man months</div>
	                        </div>
	                    </div>
	                </div>

	            </div>
	        </div>
	        <div className="container-fluid py-5">
	            <SliderView onSlideClick={(item) => onSlideClick(item)} sliderList={CaseStudy} />
	        </div>
	        {isShowCaseStudyDialog && <CaseStudyFullDialog isOpen = {isShowCaseStudyDialog} item={selectedItem} onHandleCloseDialog={() => setShowCaseStudyDialog(false)} />}
	    </section>
	    </>
    )
}

export default About;