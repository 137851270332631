import React, {useState, useEffect} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import './dialog.css';

export default function ContactusDialog(props) {
  const {isOpen, children, item = {}, onHandleCloseDialog} = props;
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');

  const [isSuccess, showSuccess] = useState(false);

  const InitialFormData = {
    name: "", 
    email: "",
    phone: "",
    message: ""
  };

  const [formData, setFormData] = useState(InitialFormData);

  const initErrorState = {
    nameError: false,
    emailError: false,
    messageError: false
  };

  const validate = (e) => {
    let isFormValid = true;

   // setFormValid(isFormValid);
    return isFormValid;
  }

  const getVal = (eleVal) => {
    return (eleVal && eleVal !== '-') ? eleVal : '';
  }

  const handleSubmit = async (e) => {
    postForm(e);
  }

  const {name="", email="", message=""} = formData;

 
  const postForm = async (e) =>  {
    e.preventDefault();
    const URL = "https://api.smmc.dev:8904/smmc-services/generic_send_email";
    //console.log(formData);

    const msgData = `Dear ${name}, 
      \n
      Thanks to reaching out to me. I will contact you soon at your mail ${email}. \n
      ${message} \n
      Thanks & Regards,
      SMMC.`

    const obj = {};
    obj.msg_body = msgData;
    obj.msg_subject = "Smmc Consultant - contact details";  
    obj.recipient = email;
    obj.cc_recipient = "saurav@smmc.net.in"

    await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(obj) // body data type must match "Content-Type" header
    })
    .then(res => res.json())
    .then((res) => {
      showSuccess(true);
    })
    .catch((error) => {
      console.log('error add send mail', error);
      alert("Unable to post the Data.. try again")
    });
  }

  const onInputChange = (e) => setFormData({...formData, [e.target.name] : e.target.value});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onHandleCloseDialog(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <Dialog 
        onClose={handleClose} 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-label=""
        >
        
      {!isSuccess && <form className="contactUsForm p-lg-5 p-3" onSubmit={(e) => handleSubmit(e)}>
        <div className="d-flex justify-content-between align-items-center">
        <h5 className="pt-2 fw-bold">Contact us</h5>
        <h5 className="contact_us_logo fw-bold">smm:consulting</h5>
        </div>
        
        <h6 className="py-3">We would like to hear from you</h6>
        
        <div class="form-group">
          <label for="email">Full Name</label>
          <input type="text" class="form-control" id="name" name="name" onChange={(e) => onInputChange(e)} required/>
        </div>

        <div className="">
          <div class="form-group mt-3">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" name="email" onChange={(e) => onInputChange(e)} required/>
          </div>
        </div>
    
        <div class="form-group mt-3">
          <label for="pwd">Message</label>
          <TextareaAutosize class="form-control pb-3" id="message" name="message" minRows="5" onChange={(e) => onInputChange(e)} />
        </div>

        <button type="submit" class="btn btn-dark mt-3 px-lg-5 px-2">Send <i className="px-2 small_icon fa-solid fa-arrow-right"></i></button>
        <button type="button" class="btn btn-outline-dark mt-3 px-lg-5 px-2 mx-3" onClick={handleClose}>Cancel</button>
      </form>}
      {
        isSuccess && <div className="d-flex flex-column align-items-center thank_you_container gray justify-content-center text-center">
        <div><i class="fa-regular fa-thumbs-up"></i></div>
        <div className="py-3">Thanks for contacting with me. Will get back to you soon.</div>
        <button className="my-4 btn border-dark" onClick={handleClose}>Close</button>
        </div>
      }
      </Dialog>
    </React.Fragment>
  );
}
