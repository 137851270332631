import React from 'react';
import './accordion.css';

class AccordionView extends React.Component {
  render() {
    const title = 'Accordion App';
    const hiddenTexts = [
    {
        label: 'Retail',
        value: 'Text of Accordion 1'
    },
    {
        label: 'Manufacturing',
        value: 'Text of Accordion 2'
    },
    {
        label: 'Pharmaceutical',
        value: 'Text of Accordion 3'
    },
    {
        label: 'E-commerce',
        value: 'Text of Accordion 4'
    },
    {
        label: 'Transportation',
        value: 'Text of Accordion 4'
    },
    {
        label: 'Multimedia',
        value: 'Text of Accordion 4'
    }
    ];
    return (
      <div>
        <Accordion hiddenTexts={hiddenTexts} />
      </div>
    );
  }
}

class Header extends React.Component {
    render() {
        return (
            <h1>{this.props.title}</h1>
        );
    }
}

class Accordion extends React.Component {
    render() {
        return (
            <div className="accordion">
                {this.props.hiddenTexts.map((hiddenText, index) => <AccordionItem index={index} key={hiddenText.label} hiddenText={hiddenText} />)}
            </div>
        );
    }
}

class AccordionItem extends React.Component {    
    constructor(props) {
        super(props);
        this.indexVal = props.index;
        this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
        this.state = {
            visibility: false
        }
    }

    
    handleToggleVisibility() {
        this.setState((prevState) => {
            return {
                visibility: !prevState.visibility,
            }
        })
    }
    render() {
        const activeStatus = this.state.visibility ? 'active' : ''

        return (
            <div className="max-width">
                <div className="row row_item">
                    <div className="col-6 py-3 w-50" onClick={this.handleToggleVisibility}>
                        <div className="PTMono-Regular domain_value">
                            {`0${this.indexVal+1}`}
                        </div>
                    </div>

                    <div className="col-6 py-3">
                      <h4 className="w-lg-50 domain_text font-600">{this.props.hiddenText.label}</h4>  
                    </div>
                </div>
            </div>
        );
    }
}

export default AccordionView;