import React, {useState, useEffect, useRef} from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Navigate , Route, Link} from 'react-router-dom';
import TextTransition, { presets } from 'react-text-transition';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import SliderView from '../../components/SliderView';
import AccordionView from '../../components/AccordionView';
import ContactusDialog from '../../components/ContactusDialog';
import Jump from 'react-reveal/Jump';
import pcc from '../../assets/pcc.png';
import hlo from '../../assets/hlo.png';
import eepl from '../../assets/eepl.png';
import partner from '../../assets/3hp.png';
import gunnebo from '../../assets/gunnebo.png';
import jbm from '../../assets/jbm.png';
import culligan from '../../assets/culligan-water-logo-vector.png';
import logo_resolvr from '../../assets/logo_resolvr.svg';
import './home.css';

import Work from '../Work/Work';
import Services from '../Services/Services';
import Teams from '../Teams/Teams';
import scroll_down from '../../assets/scroll_down.png';

const Home = () => {
  const TEXTS = ['that creates value', 'that delights customer', 'that drives growth'];
  const [index, setIndex] = useState(0);
  var intervalId = useRef(null);
  const [isShowContactDialog, setShowContactDialog] = useState(false);

  useEffect(() => {
     intervalId.current = setInterval(() => 
        {setIndex((index) => index + 1);
        },
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId.current);
  }, []);

  useEffect(() => {
    if (index === 2) {
       // clearTimeout(intervalId.current);
       setIndex(0)
    }
  }, [index]);

  return (
    <>
    <Navbar />
    <div class="scroll-up-btn">
        <i class="fas fa-angle-up"></i>
    </div>
    <section className="home" id="home">
        <div className="max-width">
            <div>
                <h1>We craft experiences</h1>
                <h2 className="py-lg-0 py-md-0 my-3">
                    <TextTransition springConfig={presets.slow}>{TEXTS[index]}</TextTransition>
                </h2>
                <div>
                    <div className="sub-text open_Sans mt-5">SMMC is an advisory collective of business designers,</div>
                    <div className="sub-text">technologist, digital designers.</div>
                    <div className="work_with_us">
                        <hr />
                        <h4 className="w-100 d-flex align-items-center" onClick={() => setShowContactDialog(true)}>
                            <a className="open_Sans w-100" href="javascript:void(0)" >
                              Work with us
                            </a>
                            <span className="ps-4">
                                <i className="fa-solid fa-arrow-right"></i>
                            </span>
                        </h4>
                    </div>
                    <div className="scroll_icon">
                        <img src={scroll_down} style={{width: '40px'}} className="my-3" />
                    </div>
                </div>
            </div>
        </div>
    </section>

   <Work />
   <Services />
   <Teams />

    <section className="skills" id="skills">
        <div className="max-width">
            <h4 className="text mt-5 ">Selected Clients</h4>
            <h2>Trusted partner of</h2>
            <div className="container gallery py-5">
                <div className="row py-4">
                    <div className="col-4">
                        <a href="https://pcchandraindia.com/"  target="_blank">
                            <img src={pcc} />
                        </a>
                    </div>
                    <div className="col-4">
                        <a href="https://www.himalayaoptical.com/" target="_blank">
                            <img src={hlo} />
                        </a>
                    </div>
                    <div className="col-4">
                        <a href="https://www.envirotecheast.com/" target="_blank">
                            <img src={eepl} />
                        </a>
                    </div>
                </div>
                <div className="row pt-4">

                    <div className="col-4 d-flex align-items-center">
                        <a href="https://3horizons.com/" target="_blank">
                            <img src={partner} />
                        </a>
                    </div>
                    <div className="col-4">
                        <a href="https://www.gunnebo.com/" target="_blank">
                            <img src={gunnebo} />
                        </a>
                    </div>
                    <div className="col-4">
                        <a href="https://www.jbmholding.com/" target="_blank">
                            <img src={jbm} />
                        </a>
                    </div>
                </div>

                <div className="row pt-4">

                    <div className="col-4 d-flex align-items-center">
                        <a href="https://www.culligan.com/" target="_blank">
                            <img src={culligan} />
                        </a>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        <a href="https://resolvr.se/" target="_blank">
                            <img src={logo_resolvr} style={{color: 'black'}} />
                        </a>
                    </div>
                </div>

            </div>
        </div>
        <Footer />
    </section>
    {isShowContactDialog && <ContactusDialog isOpen = {isShowContactDialog} onHandleCloseDialog={() => setShowContactDialog(false)} />}
    </>
  );
}

export default Home;
