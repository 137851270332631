const CaseStudy = [
	{
		"title": "DIGITAL TRANSFORMATION IN THE RETAIL (LUXURY), GEMS & JEWELLERY, MANUFACTURING",
		"challenge": "One of India's Top-5 Jewllery retailers was in need of upgrading its IT backbone to overcome the challenges (lack of scalability, managing backdated technology, data inconsistencies, difficulty in implementing process changes, over-dependence one resources) posed by its current IT landscape.",
		"client_name": "PC CHANDRA JEWELLERS",
		"client_desc": "One of India's top 5 Jewellery brand",
		"industry_focus": [
			{"name" : "(Luxury)"},
			{"name" : "Gems&Jewellery"},
			{"name" : "Manufacturing"}
		],
		"sector" : [
			{"name" : "Business Consulting"},
			{"name" : "IT Strategy"},
			{"name" : "Digital Transformation"},
			{"name" : "Project Management Services"},
			{"name" : "Continual Improvement"}
		],
		"service" : [
			{"name" : "Jewellery"},
			{"name" : "Luxury Retail"}
		],
		"year": "2015",
		"duration": "9+ years",
		"activity": [
			{"title" : "Detailed study of the business processes followed by process re-engineering"},
			{"title" : "Defining a 5-year IT Strategy & laying down a detailed IT Roadmap"},
			{"title" : "Leading the Digital Transformation (vendor management, budget & timeline management, problem-solving leadership, team development, etc.)"},
			{"title" : "Managing CXO level executives with an agile PMO reporting structure"}
		],
		"outcome" : "Successful Digital Transformation achieved within allocated budget and committed timelines that is currently delivering the business benefits of scalable & agile operational control and data-driven decision making.",
		"image" : "PCCHandra.png"
	},
	{
		"title": "RAPID PROTOTYPING (MVP) IN THE GLOBAL SAFETY PRODUCT INDUSTRY",
		"challenge": "An Europe-based global leader in the safety product industry, wanted to test a new concept around a product that would be controlled using a Mobile App.",
		"client_name": "Gunnebo ",
		"client_desc": "One of India's top 5 safety product industry",
		"industry_focus": [
			{"name" : "Manufacturing"},
			{"name" : "SafetyProduct"}
		],
		"sector" : [
			{"name" : "Safety Products"},
			{"name" : " Manufacturing"},
			{"name" : "Retailing Consulting"}
		],
		"service" : [
			{"name" : "Business Consulting"}, 
			{"name" : "IT Services"},
			{"name" : "Rapid Prototyping"}
		],
		"year": "2020",
		"duration": "3 months",
		"activity": [
			{"title" : "Detailed understanding of the business concept and 'sounding-board' to fine tune the same"},
			{"title" : "Rapid prototype (MVP) development of the Business Technology solution (both on Android & iOS platforms)over a very short timeframe and using limited budget"},
			{"title" : "Demand Testing the MVP with key internal & external stakeholders and integrating their feedback"},
			{"title" : "Finalizing the MVP features & delivering a fully functional & fine-tuned product (App) back to the business stakeholders for the next steps"}
		],
		"outcome" : "Concept idea successfully tested through rapid prototyping (MVP) over an accelerated time-frame and using limited budget. The MVP was demonstrated to potential customers to gather real-time feedback and helped the organization in critical decision making (like price points, market pull, market access channels, etc.)",
		"image" : "Gunnebo.jpg"
	},
	{
		"title": "5-YEAR IT STRATEGY DEVELOPMENT IN THE OPTICAL RETAIL INDUSTRY",
		"challenge" : "One of India's Top-3 Optical Retailers wanted to lay down a strategic IT Roadmap to drive better management control & meet their business expansion goals",
		"client_name": "Himalaya",
		"client_desc": "One of India's top 3 optical retail brands",
		"industry_focus": [
			{"name" : "Retail (Optical)"},
			{"name" : "Trading"},
			{"name" : "eCommerce"}
		],
		"sector" : [
			{"name" : "Business Consulting"},
			{"name" : "IT Strategy"},
			{"name" : "Digital Transformation"},
			{"name" : "Project Management Services"},
			{"name" : "Process Re-engineering"}
		],
		"service" : [
			{"name" : "Optical Retail"}
		],
		"year": "2018",
		"duration": "1 year",
		"activity": [
			{"title" : "Thorough understanding of the management aspirations through dedicated workshops"},
			{"title" : "Comprehensive assessment of the business processes, identifying current strengths & potential gaps"},
			{"title" : "Driving a detailed process re-engineering phase to optimise business processes and align these to global best-practices (define a detailed 'Process Bible')"},
			{"title" : "Defining a detailed 5-year IT Transformation Roadmap to support the re-engineered process backbone."},
			{"title" : "IT Roadmap covered all key aspects like budget, timeline, ideal product-implementation partner model, resource requirement, stakeholder involvement (RACI), PMO model, etc."}
		],
		"outcome" : "Successful optimization of the business processes with laying down of a comprehensive and actionable IT Roadmap. Management had a clear direction on 'why' & 'what' needs to be done & 'how' to achieve their aspired future IT landscape.",
	    "image" : "Himalaya.jpg"
	},
	{
		"title": "RAPID PROTOTYPING IN THE MANAGEMENT CONSULTING / SERVICES INDUSTRY",
		"challenge" : "A UK-based boutique management consulting firm wanted to transform its Intellectual Property (IP) on a specific Business Strategy Solution into an Application",
		"client_name": "3H",
		"client_desc": "One of India's top 3 optical retail brands",
		"industry_focus": [
			{"name" : "Services"},
			{"name" : "Management Consulting"}
		],
		"sector" : [
			{"name" : "Management Consulting"},
			{"name" : "Strategy Consulting"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "IT Services"},
			{"name" : "Rapid Prototyping "}
		],
		"year": "2019",
		"duration": "3 months",
		"activity": [
			{"title" : "Detailed understanding of the proposed concept and translating it into a Techno-functional scope document"},
			{"title" : "Deploying Agile technology to fast-track the development of the prototype (MVP) working closely with the Business Owner"},
			{"title" : "Integrating real-time user feedback from demand-testing experimentsinto the development cycle and rapidly evolving the Prototype"},
			{"title" : "Working closely with the Product Owner in sustainably improving the MVP"},
			{"title" : "Hand-over of a fully-functional and fine-tuned prototype back to the Business"}
		],
		"outcome" : "The concept idea was transformed into a fully functional business technology prototype (MVP) over a very short time-frame and using limited budget. Built on a robust & sustainable architecture, the MVP has the potential to scale up to a full-fledged commercial product.",
		"image" : "3H.jpg"
	},
	{
		"title": "BUSINESS TECHNOLOGY SOLUTION IN THE MARBLE TRADING INDUSTRY",
		"challenge" : "A Dubai-based marble trading company wanted to develop an online marketplace to trade in marbles, granite, onyx, etc.",
		"name" : "Italy based marble trading organisation",
		"client_name": "JBM",
		"client_desc": "Italy based marble trading organisation",
		"industry_focus": [
			{"name" : "Trading"},
			{"name" : "StoneStocking"}
		],
		"sector" : [
			{"name" : "Trading"},
			{"name" : "Marble B2B Sales"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "IT Services"},
			{"name" : "Rapid Prototyping "}
		],
		"year": "2021",
		"duration": "1 year",
		"activity": [
			{"title" : "Detailed understanding of the concept idea through dedicated workshops with the Business Owner"},
			{"title" : "Transforming the business scope into a detailed techno-functional document"},
			{"title" : "Development of the Online Marketplace using Agile methodology"},
			{"title" : "Cyclical review with the key business stakeholders to integrate key feedback and mature the product"},
			{"title" : "Pro-active advisory to integrate global best-practices to enhance the product quality"},
			{"title" : "Supporting the organization to host the application and making it go live"}
		],
		"outcome" : "Rapid transformation of a concept idea into a fully functional commercial application. The development cycle was both agile & adaptive fully integrating all the real-time feedback from the internal and external stakeholders.",
		"image" : "JBM.jpg"
	},
	{
		"title": "CXO LEVEL ADVISORY IN THE LUXURY RETAIL INDUSTRY",
		"challenge" : "A leading Indian luxury goods retailer wanted to have a better understanding of their sales trends in order to take improved business decisions.",
		"client_name": "PC CHANDRA JEWELLERS",
		"client_desc": "One of India's top 5 Jewellery brand",
		"industry_focus": [
			{"name" : "Retail (Luxury"},
			{"name" : "Manufacturing"}
		],
		"sector" : [
			{"name" : "Jewellery"},
			{"name" : "Luxury Retail"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "Data Analytics"}
		],
		"year": "2015",
		"duration": "9+ years",
		"activity": [
			{"title" : "Workshops with senior business stakeholders to understand requirements and set expectations"},
			{"title" : "Working with IT teams for extraction of the relevant data from the core ERP application"},
			{"title" : "Deploying the best-in-class Data Analytics techniques to analyse the data"},
			{"title" : "Highlighting the key trends with value-adding 'so-what' analyses"},
			{"title" : "Key outcomes of the engagement presented to the CXO stakeholders"}
		],
		"outcome" : "The CXO stakeholders had a better understanding of their sales trend, clearly identifying the current gaps and potential opportunities. The detailed 'so-what' analyses helped them to take evidence-based business decisions and define an internal process of data-driven decision making.",
		"image" : "PCCHandra2.png"
	},
	{
		"title": "PRICING STRATEGY IN THE LUXURY RETAIL INDUSTRY",
		"challenge" : "A UK-based leading luxury goods manufacturer & retailer was in need of revising their existing Pricing Strategy along with further enhancing their multi-channel market access strategy",
		"name" : "UK-based luxury manufacturer and retailer",
		"client_name": "Purdey",
		"client_desc": "UK-based luxury manufacturer and retailer",
		"industry_focus": [
			{"name" : "Retail (Luxury)"},
			{"name" : "Manufacturing"}
		],
		"sector" : [
			{"name" : "Luxury Manufacturing and Retailing"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "Pricing Strategy"}
		],
		"year": "2011",
		"duration": "6 months",
		"activity": [
			{"title" : "Re-definition of Pricing & Commercial Posture through the implementation of a bespoke Value-based Pricing Strategy"},
			{"title" : "Opening up new sales channels to enhance market access & drive commercial growth"},
			{"title" : "Empowering sales force through training, tools and techniques"},
			{"title" : "Hands-on support in launching the new pricing strategy"}
		],
		"outcome" : "> +15% YoY impact on retail revenues",
		"image" : "Purdey.jpg"
	},
	{
		"title": "RAPID CONCEPT IDEA TESTING IN THE PUBLIC TRANSPORTATION INDUSTRY",
		"challenge" : "An European railway major wanted to pilot test two value-added services, in terms of their user acceptance and consumer appetite prior to commercially launching these",
		"client_name": "Eurostar",
		"client_desc": "Leading European Railway",
		"industry_focus": [
			{"name" : "Railway"},
			{"name" : "PublicTransportation"}
		],
		"sector" : [
			{"name" : "Public Transport"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "Rapid Prototyping"}
		],
		"year": "2011",
		"duration": "6 months",
		"activity": [
			{"title" : "Problem-solving leadership in identification and validation of service opportunities"},
			{"title" : "Co-creation workshops/meetings with stakeholders for finalisation of concept ideas"},
			{"title" : "Conducting trials (experimentation) with closed user groups using minimum investments"},
			{"title" : "Finalising service features based on validated learning from experiment results along with hands-on support in service launch"}
		],
		"outcome" : "2 new concept ideas successfully tested using minimal investments and within 50% of traditional idea-to-implementation lead time",
		"image" : "Eurostar2.jpg"
	},
	{
		"title": "ROUTE-TO-MARKET STRATEGY IN THE FOOD SERVICES INDUSTRY",
		"challenge" : "A leading European confectionery group wanted to explore and identify the best route-to-market strategy for entering a new unexplored market",
		"client_name": "Ferrero",
		"client_desc": "Leading European confectioner",
		"industry_focus": [
			{"name" : "Services"},
			{"name" : "FoodServices"},
			{"name" : "Retail"}
		],
		"sector" : [
			{"name" : "Confectionery"},
			{"name" : "Food Services"},
			{"name" : "Retail"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "Market Research"},
			{"name" : "GTM strategy"}
		],
		"year": "2010",
		"duration": "2 months",
		"activity": [
			{"title" : "Detailed assessment of the target market, understanding the core dynamics and the key influencing factors (both positive and negative)"},
			{"title" : "Outside-in benchmarking of the key players in the segment in the concerned market (geography)"},
			{"title" : "Identification of the industry best practises and key success factors"},
			{"title" : "Recommending custom route-to-market strategy focused on maximizing ROI and minimising risk"}
		],
		"outcome" : "Route-to-market strategy defined for confectionary major to enter the US Food Service market",
		"image" : "Ferrero.jpg"
	},
	{
		"title": "PRICING STRATEGY IN THE PHARMACEUTICAL INDUSTRY",
		"challenge" : "In an increasingly competitive business environment, a global pharmaceutical major was seeking the optimal pricing strategy for their blockbuster drugs in order to maximise the bottom-line impact for their EUAfME operations.",
		"client_name": "Pfizer",
		"client_desc": "Leading US-based pharmaceutical company",
		"industry_focus": [
			{"name" : "Pharmaceutical"},
			{"name" : "LifeSciences"},
			{"name" : "Healthcare"}
		],
		"sector" : [
			{"name" : "Pharmaceutical"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "Pricing Strategy"}
		],
		"year": "2009",
		"duration": "6 months",
		"activity": [
			{"title" : "Assessment of customer perception about the blockbuster drugs compared to like-for-like products from competitors"},
			{"title" : "Identification of the key price influencing factors in the target industry/market"},
			{"title" : "Development of bespoke simulation model to propose value-based price points"},
			{"title" : "Driving stakeholder workshops to validate proposed price points and align these to market circumstances & finalising 'Pricing Corridors'"}
		],
		"outcome" : "Potential of +20% impact on bottom-line for selected drugs demonstrated and validated by key market dynamics",
		"image" : "Pfizer.jpg"
	},
	{
		"title": "PROCESS RE-ENGINEERING IN THE RETAIL (LUXURY), MANUFACTURING INDUSTRY",
		"challenge" : "A leading European luxury manufacturer was suffering from financial losses and dilution of customer experience due to a sub-optimal operational processes",
		"client_name": "Purdey",
		"client_desc": "UK-based luxury manufacturer and retail",
		"industry_focus": [
			{"name" : "Retail (Luxury)"},
			{"name" : "Manufacturing"}
		],
		"sector" : [
			{"name" : "Luxury Manufacturing and Retailing"}
		],
		"service" : [
			{"name" : "Strategy Consulting"},
			{"name" : "Operational Consulting"},
			{"name" : "Process Re-engineering"}
		],
		"year": "2009",
		"duration": "2 years",
		"activity": [
			{"title" : "Implementation of Process Re-design, Quality Control, Employee Evaluation scheme"},
			{"title" : "Definition of KPIs to re-design the order-to-manufacturing processes and launch self-sustaining continuous improvement process"},
			{"title" : "Cross-fertilisation among different initiatives - identification & execution of ad-hoc intervention needs to ensure overall alignment and unlock process bottlenecks"}
		],
		"outcome" : "Improved business processes, tighter management control, improved PnL",
		"image" : "Purdey3.jpg"
	},
	{
		"title": "CXO LEVEL ADVISORY IN THE E COMMERCE INDUSTRY",
		"challenge" : "A London-based online grocery start-up wanted to leverage its initial level of aggressive growth and transform the business both strategically & operationally to drive the next phase of expansion",
		"client_name": "Nifeislife",
		"client_desc": "UK-based online grocery",
		"industry_focus": [
			{"name" : "Retail"},
			{"name" : "e-Commerce"},
			{"name" : "OnlineFood & Groceries"}
		],
		"sector" : [
			{"name" : "E commerce"},
			{"name" : "grocery"}
		],
		"service" : [
			{"name" : "Strategy Consulting"}
		],
		"year": "2009",
		"duration": "1 year",
		"activity": [
			{"title" : "Definition & implementation of Strategic Growth Plan"},
			{"title" : "Launching bespoke scorecard to track performance KPIs and growth trends"},
			{"title" : "Strategic support to the CEO to diversify product portfolio and pricing strategy"}
		],
		"outcome" : "Successful design, implementation and KPI-driven management of strategic and operational initiatives to sustainably drive post start-up phase growth and to be subsequently considered as a key player in the niche online grocery segment",
		"image" : "Nifeislife2.jpg"
	},
	{
		"title": "VALUE-ADDED SERVICES (VAS) IN THE PHARMACEUTICAL INDUSTRY",
		"challenge" : "A leading US-based Pharmaceutical major was keen to introduce new value-added services as part of introducing innovation into its traditional product-orientated business model",
		"client_name": "Pfizer",
		"client_desc": "Leading US-based pharmaceutical",
		"industry_focus": [
			{"name" : "Pharmaceutical"},
			{"name" : "LifeSciences"},
			{"name" : "Healthcare"}
		],
		"sector" : [
			{"name" : "Pharmaceutical"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "Process design"},
			{"name" : "Rapid Prototyping"}
		],
		"year": "2008",
		"duration": "1 year",
		"activity": [
			{"title" : "Market gap/opportunity Identification, business planning & implementation"},
			{"title" : "Financial modelling focusing on P&L, pricing, target, intake rates, cost structure, etc."},
			{"title" : "Launching efficient and sustainable service lifecycle management process - from concept idea to post-launch management"}
		],
		"outcome" : "Design, implementation and KPI-driven management of multiple value-added services to cater to the different tiers of customers and multi-facets of their businesses",
		"image" : "PFizer2.jpg"
	},
	{
		"title": "DYNAMIC PRICING IN THE GAMING & MULTIMEDIA INDUSTRY",
		"challenge" : "One of the top gaming and multimedia brands in the world wanted a dynamic pricing mechanism for one of its flagship AppStore franchise games in order to maximise the financial returns from the concerned app.",
		"name" : "Global leader in gaming and multimedia",
		"client_name": "EA Sports",
		"client_desc": "Global leader in gaming and multimedia",
		"industry_focus": [
			{"name" : "Retail"},
			{"name" : "eCommerce"},
			{"name" : "Gaming&Multimedia"}
		],
		"sector" : [
			{"name" : "Gaming"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "Pricing Strategy"}
		],
		"year": "2008",
		"duration": "3 months",
		"activity": [
			{"title" : "Detailed analysis of sales data and identification of customer download trends"},
			{"title" : "Modelling an optimal pricing strategy aimed at lifecycle revenue maximisation"},
			{"title" : "Recommendations for app pricing posture at the App-store (aligned to target audience)"},
			{"title" : "Designing a bespoke pricing trigger management process backed by detailed quantitative analysis"}
		],
		"outcome" : ">25% increase on YoY lifetime revenues in the subsequently released game of the franchise compared to its previous releases",
		"image" : "EASPorts2.jpg"
	},
	{
		"title": "RAPID PROTOTYPING (MVP) IN THE FINANCIAL SERVICES INDUSTRY",
		"challenge" : "A boutique European advisory firm was exploring options to scale up their excel-based proprietary business tool",
		"client_name": "3H",
		"client_desc": "UK based boutique strategy consulting organisation",
		"industry_focus": [
			{"name" : "FinancialServices"},
			{"name" : "Consulting"},
		],
		"sector" : [
			{"name" : "Management Consulting"},
			{"name" : "Strategy Consulting"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "IT Services"},
			{"name" : "Rapid Prototyping"}
		],
		"year": "2019",
		"duration": "6 months",
		"activity": [
			{"title" : "Detailed Business Requirement gathered based on existing excel-based tool"},
			{"title" : "Technical Specifications outlined & Modular implementation Plan chalked out"},
			{"title" : "Agile methodology deployed to deliver the web-based solution over an aggressive timeline"},
			{"title" : "Staged go-live of the platform with release of each Modular scope"},
			{"title" : "Rapid integration of user feedback and accelerated tool evolution achieved"}
		],
		"outcome" : "Rapid go-to-market achieved. Accelerated fine-tuning with customer feedback integration. Drastic reduction in the cost by effectively deploying an off-shore model",
		"image" : "3HOrizons2.jpg"
	},
	{
		"title": "HANDOVER MAINTEANANCE AND SUPPORT OF THE TOOL CULLIGAN-ENIGMA",
		"challenge" : "Taking the handover of the entire solution from an existing vendor of Culligan without any knowledge transfer. Proposed re-architecture on the solution to optimize performance the tool.Providing support to Culligan on regular interval",
		"client_name": "Culligan",
		"client_desc": "A US based global Water treatment company",
		"industry_focus": [
			{"name" : "Support&maintenance"},
			{"name" : "Consulting"},
			{"name" : "Enterprise"},
		],
		"sector" : [
			{"name" : "Support and maintenance"},
			{"name" : "Business Consulting"}
		],
		"service" : [
			{"name" : "Business Consulting"},
			{"name" : "Maintenance and Support"},
			{"name" : "Enterprise"}
		],
		"year": "2019",
		"duration": "6 months",
		"activity": [
			{"title" : "SMMC proposed re-architecture of the existing codebase of the tool, to reduce unnecessary process flow and to optimize the table structure"},
			{"title" : "As a regular activity, SMMC updated the database with new sales data extracted from Jangle Scout on weekly basis"},
			{"title" : "SMMC provided support to Culligan whenever it is required like recategorization, re-branding any item etc"},
			{"title" : "SMMC also supported Culligan by proving exported data directly from the database for the requested filter criteria"},
		],
		"outcome" : "Successfully enhanced and maintaining the entire product after getting the ownership of this Tool and supporting customer on regular basis to provide the best in class possible solution",
		"image" : "culligan.png"
	}
]

export default CaseStudy;