import React, {useState} from 'react';
import AccordionView from '../../components/AccordionView';
import FullDialog from '../../components/FullDialog';
import AnimatedCard from '../../components/AnimatedCard';
import './service.css';

const Services = () => {
  const [isshowDialog, setShowDialog] = useState(false);

  return (
    <section className="services" id="services">
      <div className="max-width">
         <h4>Our Services</h4>
         <h2 className="pt-2">Empowering sustainable & value-driven growth</h2>
         <div className="sub-text py-2 sub_text_container open_Sans">
            <div className="py-4">SMMC is an advisory collective of business designers, technologist, digital designers.</div>
            <hr />
            <h4 className="d-flex justify-content-between align-items-center" onClick={() => setShowDialog(true)}>
              <a href="javascript:void(0)" >                More about our services
                
              </a>
              <span className="pe-4 py-3">
                <i className="fa-solid fa-arrow-right"></i>
              </span>
            </h4>
         </div>
          
          <div className="py-4">
              <AnimatedCard />
          </div>
          <div className="pt-3">
            <h4>Business Domains</h4>
            <h2 className="pt-2">Industries that we excelled</h2>
          </div>
      </div>
      <div>
        <div className="service-content py-5">
          <AccordionView />
        </div>
      </div>
      {isshowDialog && <FullDialog isOpen = {isshowDialog} onHandleCloseDialog={() => setShowDialog(false)} />}
    </section>
  )
}

export default Services;

