import './animatedcard.css';
import Tech_enablement from '../assets/tech_focus_300.png';
import digital_design from '../assets/digital_design.png';
import bussiness_consult from '../assets/business_consulting_300.png';
import profile_5 from '../assets/profile-5.jpeg';

const AnimatedCard = () => {
	return (
	<>
	<div class="container">
		<div className="row">
		    <div class="col-lg-4 py-4">
		    	<div class="house-inner">
		    		<div class="card p-4">
		    			<div>
		    				<div>
		    					<img src={Tech_enablement} className="card_icon" />
		    				</div>
		    				<h3 className="px-2 px-lg-0 font_600">Business</h3>
              				<h3 className="px-2 px-lg-0 font_600">Consulting</h3>
		    				<div className="sub_text py-2 px-lg-0 px-2 open_Sans">Together, we define strategic vision</div>

		    				
		    			</div>
		    		</div>
		    	</div>
	    	</div>

	    	<div class="col-lg-4 py-4">
		    	<div class="house-inner">
		    		<div class="card p-3">
		    			<div>
		    				<div>
		    					<img src={digital_design} className="card_icon" />
		    				</div>
		    				<h3 className="px-2 px-lg-0 font_600">Digital</h3>
              				<h3 className="px-2 px-lg-0 font_600">Consulting</h3>
		    				<div className="sub_text py-2 px-lg-0 px-2 open_Sans">Together, we define strategic vision</div>
		    			</div>
		    		</div>
		    	</div>
	    	</div>

	    	<div class="col-lg-4 py-4">
		    	<div class="house-inner">
		    		<div class="card p-3">
		    			<div>
		    				<div>
		    					<img src={bussiness_consult} className="card_icon" />
		    				</div>
		    				<h3 className="px-2 px-lg-0 font_600">Technology</h3>
              				<h3 className="px-2 px-lg-0 font_600">Enablement</h3>
		    				<div className="sub_text py-2 px-lg-0 px-2 open_Sans">Together, we define strategic vision</div>
		    			</div>
		    		</div>
		    	</div>
	    	</div>
	    </div>
	</div>
    </>

	)
}

export default AnimatedCard;