import { BrowserRouter as Router, Routes, Navigate , Route, Link} from 'react-router-dom';
import Home from './pages/Home/Home';

import Navbar from './pages/Navbar/Navbar';

import Policy from './pages/Policy/Policy';
import './App.css';

function App() {
  return (
    <Router> 
      <div>
        {/*<Navbar />*/}
        <Routes>
          <Route exact path='/' element={<Home />}/>
          <Route exact path='/policy' element={<Policy />}/>
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
