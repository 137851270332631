const SpecialList = [
	{
		"index" : "01",
		"title" : "Rapid Prototyping",
		"listItem" : [
			{"label" : "Concept Idea Sounding Board"},
			{"label" : "Feature definition"},
			{"label" : "MVP scoping"},
			{"label" : "Technology Consulting"},
			{"label" : "MVP development"},
			{"label" : "Rapid Pivoting (based on demand testing)"},
			{"label" : "MVP adaptation & product stabilisation"},

		]
	},
	{
		"index" : "02",
		"title" : "Digital Transformation",
		"listItem" : [
			{"label" : "Objective Setting"},
			{"label" : "Laying foundation for Digital Transformation"},
			{"label" : "Defining the Technical Backbone"},
			{"label" : "Technology Advisory"},
			{"label" : "PMO Services to anchor the Digital Transformation"},
			{"label" : "Mapping end results with Project Objectives"}
		]
	},
	{
		"index" : "03",
		"title" : "Product / Service Definition & Delivery",
		"listItem" : [
			{"label" : "Business Innovation (new products / services)"},
			{"label" : "Business Case Definition"},
			{"label" : "Product / Service Portfolio Optimization"},
			{"label" : "Vendor Management / Sourcing Strategy"},
			{"label" : "Supply Chain Management"},
			{"label" : "Distribution and Routing strategy"}
		]
	},
	{
		"index" : "04",
		"title" : "Business Process Optimisation",
		"listItem" : [
			{"label" : "Detailed Business Process Study"},
			{"label" : "Identifying key gaps / improvement areas"},
			{"label" : "Aligning Processes to Strategic objectives"},
			{"label" : "Integrating Best Practices"},
			{"label" : "Defining re-engineered processes"},
			{"label" : "Integrating optimised processes"}
		]
	},
	{
		"index" : "05",
		"title" : "Market Research & Data Analytics",
		"listItem" : [
			{"label" : "Focused Market Research"},
			{"label" : "Primary Research"},
			{"label" : "Secondary Research"},
			{"label" : "Communication Strategies"},
			{"label" : "Data Analytics"},
			{"label" : "Data-driven decision making"}
		]
	},
	{
		"index" : "06",
		"title" : "Business Technology / IT Services",
		"listItem" : [
			{"label" : "ERP Implementation"},
			{"label" : "Mobile Apps"},
			{"label" : "Web Platforms"},
			{"label" : "Website Design"},
			{"label" : "IOT Strategies & Implementation"}
		]
	}
]

export default SpecialList;