import SliderView from '../../components/SliderView';
import profile_3 from '../../assets/profile_3.jpg';
import './team.css';

const Teams = () => {
	return (
		<>
			<section className="teams" id="team">
		        <div className="max-width">
		           <h4>Our Team</h4>
		           <h2 className="">A collective that consistently goes above and beyond.</h2>
		           <div className="team-text py-4">Our team boasts a diverse talent in digital design, technology, and business strategy. With a proven track-record both with Start ups, SMEs and corporate powerhouses developed across three different continents, we offer a unique proposition that delivers both immediate results and sustainable long-term impact to your business profitability and operations.</div>
		           
		           <div className="py-4">
		                <h4>Roles at which we are good at</h4>
		                <div className="stretegy_text py-4">Strategy Consultant, Process Consultants, Business Analysts, Mobility Architects, Project Managers, Software Developers, IOT Specialists, Cloud Computing Specialists, Data Scientists</div>
		           </div>

		           <div className="d-lg-flex pt-3">
		            <div>
		                <img src={profile_3} style={{width: '200px', borderRadius: '5px'}} />
		            </div>
		            <div className="px-lg-4 py-2 py-lg-0">
		                <h3 className="author_name">Saurav Majumder</h3>
		                <h4 className="author_designation">MBA, B.Tech</h4>
		                <div className="team-text py-4">
		                    The firm is led by Saurav Majumder, an MBA-qualified management consultant, with more than 10+ years of ‘C Suite’ level consulting experience in Europe, the USA and Asia across a wide spectrum of industry verticals including Pharmaceutical, Retail, Services, Transport, Multimedia, and eCommerce. With proven industry experience and a dynamic breadth of exposure, SMMC is primed to support you in achieving your business goals.
		                </div>
		            </div>
		           </div>
		        </div>
		    </section>
		</>
	)
}

export default Teams;