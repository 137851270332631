import React, {useState, useEffect} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import './dialog.css';

export default function FullDialog(props) {
  const {isOpen, children, item = {}, onHandleCloseDialog} = props;
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onHandleCloseDialog(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <Dialog 
        onClose={handleClose} 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-label=""
        >
        <div className="dialog_content">
          <div className="dialog_header p-lg-5 p-3">
            <div>
              <h4 className="py-4">{item.name}</h4>
              <h3>{item.title}</h3>
            </div>

            <div className="container pt-4">
              <div className="w-lg-25 d-flex justify-content-between">
                <div className="">
                  <h4>Year</h4>
                  <p className="dialog_sub_heading">{item.year}</p>
                </div>
                <div className="">
                  <h4>Duration</h4>
                  <p className="dialog_sub_heading">{item.duration}</p>
                </div>
              </div>
            </div>

            <div className="container pt-4">
              <div className="d-lg-flex">
                <h4 className="pe-lg-4 sector_name">Services : </h4>
                <div className="box">
                  {item.service.map((sv, index) => {
                    return <span className="dialog_tablet_view me-1 my-1">{sv.name}</span>
                  })
                  }
                </div>
              </div>
            </div>

            <div className="container pt-4">
              <div className="d-lg-flex">
                <h4 className="pe-lg-4 sector_name">Sector :  </h4>
                <div className="box">
                  {item.sector.map((sc, index) => {
                    return <span className="dialog_tablet_view me-1 my-1">{sc.name}</span>
                  })
                  
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid px-lg-5 px-3 py-3">
            <div className="my-3">
              <h3>The Challenge</h3>
              <p className="py-2">{item.challenge}</p>
            </div>

            <div className="contain my-3">
              <h3>The Results</h3>
              <div className="row py-2">
                <div className="col-lg-6">
                  <h4 className="py-2">Solution</h4>
                  <ul className="dialog_content_list py-2">
                    {item.activity.map((listItem, index) => 
                      <li>{listItem.title}</li>
                    )}
                  
                  </ul>
                </div>
                <div className="col-lg-6">
                  <h4 className="py-2">Outcome</h4>
                  <p className="dialog_content_list py-2">{item.outcome}</p>
                </div>
              </div>
            </div>
          </div>

          <button className="dialog_close ax-button" onClick={handleClose}>
            <i class="fa-regular fa-circle-xmark" style={{fontSize: '30px', opacity: .7}}></i>
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
